<template>
  <div class="bilichat">
    <el-card :body-style="{ padding: '0px' }" v-bind:class="{ 'el-card-darkmode': DarkMode }">
      <el-row>
        <el-col>
          <iframe
              class="chatroom"
              src="https://blc.lolicon.app/live.html#room=10481&amp;face=gift"
              width="100%"
              height="100%"
              frameborder="0"
              scrolling="no"
          >
          </iframe>
        </el-col>
        <el-col>
          <el-button class="openchat" @click="openbilichat" type="primary" size="small" style="border-radius: 0;"
          >打开虚空聊天室
          </el-button
          >
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "BiliChat",
  methods: {
    openbilichat: function () {
      window.open(
          "https://live.bilibili.com/10481#aside-area-vm",
          "",
          "width=300,height=735"
      );
    },
    get() {
      eval("debugger");
    },
  },
  mounted() {
    this.timer = setInterval(this.get, 200);
  },
  props: ['DarkMode'],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bilichat {
  height: 720px;
}

.chatroom {
  height: 684px;
}

.openchat {
  width: 100%;
}

.el-card-darkmode {
  background-color: #252526;
  border: 1px solid #252526;
  box-shadow: 0 2px 12px 0 rgb(255 255 255 / 25%);
}
</style>
