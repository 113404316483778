<template>
  <div id="app">
    <el-row>
      <el-col :span="24">
        <h1 class="title-box" v-bind:class="{ 'title-box-darkmode': darkMode }">
          <span class="logo-text"></span><span class="red-text">Virtual</span><span class="blue-text">Live</span>
        </h1>
        <p class="black-text" v-bind:class="{ 'black-text-darkmode': darkMode }">
          一个安心看转播的小站：手机端横屏或全屏体验更佳（注意：iOS由于不支持flv流，所以会出现无法播放的情况）
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20">
        <FlvPlayer :DarkMode="darkMode"></FlvPlayer>
      </el-col>
      <el-col :span="4">
        <BiliChat :DarkMode="darkMode"></BiliChat>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="note-right">
          <el-button type="primary" size="small" style="float: right;" @click="switchDarkMode">{{ darkMode ? '明亮模式':'黑暗模式' }}</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FlvPlayer from "./components/FlvPlayer";
import BiliChat from "./components/BiliChat";

export default {
  name: "App",
  components: {
    FlvPlayer,
    BiliChat,
  },
  data() {
    return {
      darkMode: false,
      antiTextx: 'MeaAqua !',
    };
  },
  methods: {
    switchDarkMode() {
      this.darkMode = !this.darkMode;
      if(this.darkMode){
        document.getElementsByTagName("body")[0].setAttribute("style","background:#1E1E1E");
      }else{
        document.getElementsByTagName("body")[0].setAttribute("style","background:#F4F4F5");
      }
    },
    MouseoverText() {
      this.antiTextx = 'MeaAqua!';
    },
    MouseleaveText() {
      this.antiTextx = 'MeaAqua!';
    },
  },
  created() {
    this.MouseleaveText();
    var now_hours = new Date().getHours();
    if (now_hours >= 19 || now_hours <= 6) {
      this.switchDarkMode();
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 1550px;
  margin-left: auto;
  margin-right: auto;
}
.note-right {
  float: right;
  font-size: 13px;
  line-height: 5px;
  margin-top: 5px;
}
.note-left {
  float: left;
  font-size: 13px;
  line-height: 5px;
  margin-top: 5px;
}

.red-text {
  color: #FFF;
  font-size: 36px;
  margin-left: 3px;
}

.blue-text {
  color: #000;
  border-radius: 4px;
  background-color: #FF9900;
  font-size: 36px;
  margin-left: 8px;
}

.black-text{
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  white-space: pre;
}

.black-text-darkmode {
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 6px rgba(255, 255, 255, 0.8);
  transition: .3s;
}

.logo-text {
  font-size: 36px;
}

.title-box {
  background-color: #000;
  width: 240px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content:center;
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: .3s;
}

.title-box-darkmode {
  background-color: rgba(255, 255, 255, 0);
  transition: .3s;
}

.anti-text-box {
  width: 220px;
  height: 20px;
  text-align: right;
}
</style>
